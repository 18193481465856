//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import StoresListTable from '@/modules/stores/components/stores-list-table.vue';

export default {
  name: 'app-stores-list-page',

  components: {
    [StoresListTable.name]: StoresListTable,
  },

  computed:{
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      // return localStorage.getItem('language') == 'ar'
      return this.currentLanguageCode == 'ar'
    },
  },
};
